<template>
    <div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.address">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Primary Contact -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Primary Address</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Line 1" placeholder="Line 1" type="text" validation="required" v-model="address.primary_address.line_1" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Line 2" placeholder="Line 2" type="text" v-model="address.primary_address.line_2" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-3 gap-6">
                            <FormulateInput class="w-full" label="City" placeholder="City" validation="required" type="text" v-model="address.primary_address.city" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            
                            <!-- <FormulateInput class="w-full" label="Country" placeholder="Country" validation="required" type="text" v-model="address.primary_address.country" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" /> -->

                            <div>
                                <h2 class="mb-1 mt-1">Country</h2>

                                <Dropdown
                                    placeholder="Country"
                                    :options="countries"
                                    searchable
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    :value="address.primary_address.country"
                                    @input="(value) => updateCountry(value, 'primary_address')"
                                    :config="{ label: 'name', trackBy: 'name' }"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                    height="20px"
                                    :loading="loadingState.country"
                                />
                            </div>

                            <FormulateInput class="w-full" label="Postcode" placeholder="Postcode" validation="required|matches:/^[a-zA-Z0-9\s\-]+$/" :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" type="text" v-model="address.primary_address.zip_code" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>

                <!-- Accounts Contact -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Billing Address</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Line 1" placeholder="Line 1" type="text" validation="required" v-model="address.billing_address.line_1" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Line 2" placeholder="Line 2" type="text" v-model="address.billing_address.line_2" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-3 gap-6">
                            <FormulateInput class="w-full" label="City" placeholder="City" validation="required" type="text" v-model="address.billing_address.city" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />

                            <!-- <FormulateInput class="w-full" label="Country" placeholder="Country" validation="required" type="text" v-model="address.billing_address.country" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" /> -->
                            <div>
                                <h2 class="mb-1 mt-1">Country</h2>

                                <Dropdown
                                    placeholder="Country"
                                    :options="countries"
                                    searchable
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    :value="address.billing_address.country"
                                    @input="(value) => updateCountry(value, 'billing_address')"
                                    :config="{ label: 'name', trackBy: 'name' }"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                    height="20px"
                                    :loading="loadingState.country"
                                />
                            </div>

                            <FormulateInput class="w-full" label="Postcode" placeholder="Postcode" validation="required|matches:/^[a-zA-Z0-9\s\-]+$/" :validation-messages="{ matches: 'Only characters supported are alphanumeric, - and spaces' }" type="text" v-model="address.billing_address.zip_code" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex my-4 justify-between w-full">
                <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                <Button :loader="loadingState.submit" text="Save & Next" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { createAndUpdateClientAddress, getAddresses, getCountryList } from "../services";
import Loader from "@shared/loader";
import Dropdown from "@shared/components/dropdown-base";

export default {
    name: "client-admin-address",
    components: {
        Button,
        Loader,
        Dropdown,
    },
    props: {},
    data() {
        return {
            address: {
                primary_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: false,
                },
                billing_address: {
                    line_1: "",
                    line_2: "",
                    city: "",
                    country: "",
                    zip_code: false,
                },
            },
            loadingState: {
                address: false,
                country: false,
                submit: false
            },
            clientId: null,
            countries: [],
        };
    },
    async mounted() {
        await this.getClientAddress();
        await this.fetchCountry();
    },
    methods: {
        async fetchCountry() {
            this.loadingState.country = true;
            this.countries = await getCountryList();
            this.loadingState.country = false;
        },
        async getClientAddress() {
            this.loadingState.address = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await getAddresses(this.clientId);
                    this.address = this.convertResponse(response);
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.address = false;
        },
        convertResponse(addresses) {
            if (addresses.length > 0) {
                let primary_address = addresses?.find((el) => el.address_type === "Primary Address");
                let billing_address = addresses?.find((el) => el.address_type === "Billing Address");
                let address = {
                    primary_address,
                    billing_address,
                };
                return address;
            } else {
                return this.address;
            }
        },
        async handleSubmit(hasErrors) {
            this.loadingState.submit = true
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            } else {
                let payload = [];
                payload.push({ ...this.address.primary_address, address_type: "Primary Address" });
                payload.push({ ...this.address.billing_address, address_type: "Billing Address" });
                try {
                    let response = await createAndUpdateClientAddress(this.clientId, payload);
                    this.$emit('handleNextSection')
                    this.$toast.success(response.message || "Saved!");
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to Update address");
                }
            }
            this.loadingState.submit = false
        },
        updateCountry(value, type) {
            this.address = {...this.address, [type] : {...this.address[type], country:value.name}}
        },
    },
};
</script>

<style lang="scss" scoped>
    .dropdown{
        min-height: 40px !important;
    }
</style>
