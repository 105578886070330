<template>
    <div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.fetching">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Primary Contact -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Primary Contact</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Name" placeholder="Name" type="text" validation="required" v-model="contact.primary_contact.name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Position" placeholder="Position" type="text" v-model="contact.primary_contact.position" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Phone" validation="required" placeholder="Phone " type="text" v-model="contact.primary_contact.phone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Email" validation="required" placeholder="Email" type="email" v-model="contact.primary_contact.email" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="flex 2xl:justify-end justify-between">
                            <div class="w-full flex justify-between">
                                <FormulateInput class="w-full" label="Received client action alert emails" placeholder="Received client action alert emails" type="checkbox" v-model="contact.primary_contact.receive_client_action_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                <FormulateInput class="w-full" label="Received final report alert emails" placeholder="Received final report alert emails" type="checkbox" v-model="contact.primary_contact.receive_final_report_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                            </div>
                        </div>
                        <div class="w-1/2">
                            <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea" v-model="contact.primary_contact.note" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>

                <!-- Accounts Contact -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Accounts Contact</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Name" placeholder="Name" type="text" validation="required" v-model="contact.accounts_contact.name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Position" placeholder="Position" type="text" v-model="contact.accounts_contact.position" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Phone" validation="required" placeholder="Phone " type="text" v-model="contact.accounts_contact.phone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Email" validation="required" placeholder="Email" type="email" v-model="contact.accounts_contact.email" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="flex 2xl:justify-end justify-between">
                            <div class="w-full flex justify-between">
                                <FormulateInput class="w-full" label="Received client action alert emails" placeholder="Received client action alert emails" type="checkbox" v-model="contact.accounts_contact.receive_client_action_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                <FormulateInput class="w-full" label="Received final report alert emails" placeholder="Received final report alert emails" type="checkbox" v-model="contact.accounts_contact.receive_final_report_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                            </div>
                        </div>
                        <div class="w-1/2">
                            <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea" v-model="contact.accounts_contact.note" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>

                <!-- Addition Contacts -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Additional Contact</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <FormulateInput type="group" limit="3" @repeatableRemoved="removeOptionalContact($event)" :repeatable="true" name="additional_contacts" add-label="+ Add Addition Contact" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="contact.additional_contacts" validation="max:3,length">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Name" placeholder="Name" type="text" validation="required" name="name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Position" placeholder="Position" type="text" name="position" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Phone" validation="required" placeholder="Phone " type="text" name="phone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Email" placeholder="Email" validation="required" type="email" name="email" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="flex 2xl:justify-end justify-between">
                                <div class="2xl:w-1/2 w-full flex justify-between">
                                    <FormulateInput class="w-full" label="Received client action alert emails" placeholder="Received client action alert emails" type="checkbox" name="receive_client_action_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                    <FormulateInput class="w-full" label="Received final report alert emails" placeholder="Received final report alert emails" type="checkbox" name="receive_final_report_alert" :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                </div>
                            </div>
                            <div class="w-1/2">
                                <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea" name="note" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </FormulateInput>
                    </div>
                </div>
            </div>
            <div class="flex my-4 w-full justify-between">
                <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                <Button :loader="loadingState.submit" text="Save &amp; Next" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import axios from "@/axios";
import { fetchClientContacts, createAndUpdateClientContacts } from "../services";

export default {
    name: "client-admin-contacts",
    components: {
        Button,
        Loader,
    },
    props: {},
    data() {
        return {
            contact: {
                primary_contact: {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                },
                accounts_contact: {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                },
                additional_contacts: [],
            },
            clientId: null,
            loadingState:{
                submit:false,
                fetching : false, 
            }
        };
    },
    async mounted() {
        await this.getClientContacts();
    },
    methods: {
        async handleSubmit(hasErrors) {
            this.loadingState.submit = true
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            } else {
                let primary_contact = {
                    ...this.contact.primary_contact,
                    contact_type: "Primary Contact",
                    // name: this.contact.primary_contact.name,
                    // position: this.contact.primary_contact.position,
                    // phone: this.contact.primary_contact.phone,
                    // email: this.contact.primary_contact.email,
                    // receive_client_action_alert: this.contact.primary_contact.receive_client_action_alert,
                    // receive_final_report_alert: this.contact.primary_contact.receive_final_report_alert,
                    // note: this.contact.primary_contact.note,
                };
                let accounts_contact = {
                    ...this.contact.accounts_contact,
                    contact_type: "Accounts Contact",
                    // name: this.contact.primary_contact.name,
                    // position: this.contact.primary_contact.position,
                    // phone: this.contact.primary_contact.phone,
                    // email: this.contact.primary_contact.email,
                    // receive_client_action_alert: this.contact.primary_contact.receive_client_action_alert,
                    // receive_final_report_alert: this.contact.primary_contact.receive_final_report_alert,
                    // note: this.contact.primary_contact.note,
                };
                let additional_contact = this.contact.additional_contacts?.map((el) => ({ ...el, contact_type: "Additional Contact" }));
                let contacts = [];
                contacts.push(primary_contact, accounts_contact, ...additional_contact);
                try {
                    let response = await createAndUpdateClientContacts(this.clientId, contacts);
                    this.contact = this.convertResponse(response);
                    this.$emit('handleNextSection')
                    this.$toast.success(response.message || 'Saved!')
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to update contact");
                }
                this.loadingState.submit = false
            }
        },
        async getClientContacts() {
            this.loadingState.fetching = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await fetchClientContacts(this.clientId);
                    this.contact = this.convertResponse(response);
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.fetching = false;
        },

        convertResponse(contacts) {
            if (contacts.length > 0) {
                let empty_contact = {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                }
                let primary_contact = contacts?.find((el) => el.contact_type === "Primary Contact") || empty_contact;
                let accounts_contact = contacts?.find((el) => el.contact_type === "Accounts Contact") || empty_contact;
                let additional_contacts = contacts?.filter((el) => el.contact_type === "Additional Contact");
                let new_contacts = {
                    primary_contact,
                    accounts_contact,
                    additional_contacts,
                };
                return new_contacts;
            } else {
                return this.contact;
            }
        },
        async removeOptionalContact(contacts) {
            let contact = contacts[0];
            if (contact && contact.id) {
                let url = `/client/${this.clientId}/contact/${contact.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.$toast.success(data.message || "Removed!");
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to remove!");
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
