<template>
    <div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.client">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Configuration -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Configuration</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput
                                class="w-full"
                                label="Retention Period (should be in Days)"
                                placeholder="Retention Period (should be in Days)"
                                type="number"
                                validation="required|number|min:1|max:30000|number|matches:/^[+]?\d+$/"
                                :validation-messages="{
                                    matches: 'Retention period should be a whole number.',
                                }"
                                v-model="configuration.retention_period"
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            />
                            <FormulateInput class="w-full" label="Max Users" placeholder="Max Users" validation="required|number|min:0,number" type="number" v-model="configuration.max_users" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Max Candidates" placeholder="Max Candidates" validation="required|number|min:0,number" type="number" v-model="configuration.max_candidate" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Prefix" placeholder="Prefix" validation="required|min:3,length" :validation-messages="{ matches: 'Prefix must be lowercase.' }" type="text" v-model="configuration.prefix" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex my-4  w-full justify-between">
                <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                <Button :loader="isFormSubmit" :text="`${isClientOnBoarded ? 'Save & Next' : 'Save and Client onboard'}`" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { fetchClientInfo } from "../services";
import Loader from "@shared/loader";

export default {
    name: "client-configuration",
    components: {
        Button,
        Loader
    },
    props: {
        clientDetails:{
            type: Object,
            default: () => {}
        },
        loadingStates:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            configuration: {
                retention_period: 0,
                max_users: 0,
                max_candidate: 1,
                prefix: null,
            },
            loadingState: {
                client: false,
            },
            clientId: null,
        };
    },
    async mounted() {
        await this.getClientDetails();
    },
    computed:{
        isClientOnBoarded(){
            return this.clientDetails.onboarded || false
        },
        isFormSubmit(){
            return this.loadingStates.formSubmit
        }
    },
    methods: {
        async getClientDetails() {
            this.loadingState.client = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                let response = await fetchClientInfo(this.clientId);
                let { retention_period, max_users, max_candidate, prefix, onboarded } = response;
                this.isClientOnboarded = onboarded
                this.configuration = { retention_period, max_users, max_candidate, prefix };
            }
            this.loadingState.client = false;
        },
        handleSubmit(hasErrors) {
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            } else {
                let payload = {
                    data: this.configuration,
                    onBoard: !this.isClientOnboarded
                }
                this.$emit("submitForm", payload);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
