<template>
    <div class="flex flex-col gap-2 flex-1">
        <div class="flex justify-between ">
            <Button text="back" v-if="selectedGroup" class="btn btn-outline btn--dark--outline btn-xs" @click="backToGroups()" />
            <Button :text="`${selectedGroup ? 'Add User' : 'Add Group'}`" @click="handleShowModal()" type="primary" class="ml-auto" />
        </div>
        <vue-good-table v-if="selectedGroup" class="flex-1" :columns="users.columns" :rows="users.rows" :key="selectedGroup"> </vue-good-table>
        <vue-good-table v-else class="flex-1" :columns="groups.columns" :rows="groups.rows">
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'title'" class="flex gap-3">
                    <p class="text-primary cursor-pointer" @click="handleGroupClick(props.row.title)">{{ props.row.title }}</p>
                </div>
            </template>
        </vue-good-table>
        <GroupModal :groupname.sync="group.groupname" :groupdescription.sync="group.groupdescription" :disabled="!group.groupname || !group.groupdescription" @submit="addGroup" />
    </div>
</template>

<script>
import Button from "@shared/components/button";
import GroupModal from "@/pages/group-details/create-group-modal";
// import Dropdown from "@shared/components/dropdown-base";

export default {
    name: "groups",
    components: {
        Button,
        GroupModal,
        // Dropdown,
    },
    props: {},
    data() {
        return {
            groups: {
                columns: [
                    {
                        label: "Group Name",
                        field: "title",
                    },
                    {
                        label: "No of users",
                        field: "user_count",
                    },
                    {
                        label: "Group decription",
                        field: "description",
                    },
                ],
                rows: [
                    {
                        id: 1,
                        title: "User One",
                        user_count: 56,
                        description: "uas",
                    },
                    {
                        id: 1,
                        title: "User Oneasdasdas",
                        user_count: 56,
                        description: "adfsad",
                    },
                ],
            },
            users: {
                columns: [
                    {
                        label: "User Name",
                        field: "title",
                    },
                    {
                        label: "Decription",
                        field: "description",
                    },
                ],
                rows: [],
            },
            tenant_id: null,
            selectedGroup: null,
            group:{
                groupname: '',
                groupdescription:''

            }
        };
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        if (this.$route.query.group) {
            this.selectedGroup = this.$route.query.group;
        }
        this.tenant_id = this.$store.getters.getTenantId;
    },
    methods: {
        handleGroupClick(group) {
            this.selectedGroup = group;
            this.$router.push({ query: { group } });
        },
        backToGroups() {
            this.selectedGroup = null;
            this.$router.push({ query: {} });
        },
        addGroup(){
            console.log("hello")
        },
        handleShowModal() {
            this.group = {
                groupname: '',
                groupdescription: ''
            }
            this.$modal.show("group-modal");
        },
    },
    watch: {
        selectedGroup: function (val) {
            this.$emit("setBredCrumbs", val);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
