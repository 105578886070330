<template>
    <div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.client">
            <Loader />
        </div>
        <FormulateForm #default="{ hasErrors }" v-else>
            <div class="flex flex-col gap-6">
                <!-- Financial Details -->
                <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Financial Details</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Tax number (eg. VAT, GSTIN etc.)" placeholder="Tax number (eg. VAT, GSTIN etc.)" type="number" v-model="financials.financial_details.tax_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <div>
                                <h2 class="mb-2 mt-1">Currency</h2>
                                <Dropdown
                                    placeholder="Currency"
                                    :options="currencyOptions"
                                    searchable
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    :value="financials.financial_details.currency"
                                    @input="(value) => updateCurrency(value)"
                                    :config="{ label: 'code', trackBy: 'code' }"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                    :loading="loadingState.currency"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Purchase Details -->
                <!-- <div>
                    <h1 class="text-gray-700 font-semibold text-lg mb-3">Billing Address</h1>
                    <div class="bg-white p-6 rounded-lg">
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Purchase Account" placeholder="Purchase account" type="text" v-model="financials.purchase_details.purchase_account" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Billing due date" placeholder="Billing due date" type="date" v-model="financials.purchase_details.billing_due_date" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                        <div class="grid grid-cols-2 gap-6 mb-6">
                            <div>
                                <h2 class="mb-2 mt-1">Currency</h2>
                                <Dropdown
                                    placeholder="Amounts are"
                                    :options="amountsAreOptions"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    v-model="financials.purchase_details.amounts_are"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                />
                            </div>

                            <div>
                                <h2 class="mb-2 mt-1">Currency</h2>
                                <Dropdown
                                    placeholder="purchase VAT"
                                    :options="purchaseVatOptions"
                                    :colors="{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    }"
                                    v-model="financials.purchase_details.purchase_vat"
                                    maxWidth="100%"
                                    minWidth="100%"
                                    width="102px"
                                />
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-6">
                            <FormulateInput class="w-full" label="Type" placeholder="Type" type="text" v-model="financials.purchase_details.type" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Salesman" placeholder="Salesman" type="text" v-model="financials.purchase_details.salesman" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="flex my-4  w-full justify-between">
                <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
                <Button text="Save & Next" :loader="isFormSubmit" type="primary" @click="handleSubmit(hasErrors)" />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Dropdown from "@shared/components/dropdown-base";
import { fetchClientInfo, getCurrencyList } from "../services";
import Loader from "@shared/loader";

export default {
    name: "client-financial",
    components: {
        Button,
        Dropdown,
        Loader,
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            financials: {
                financial_details: {
                    tax_number: "",
                    currency: "",
                },
                // purchase_details: {
                //     purchase_account: "",
                //     billing_due_date: "",
                //     amounts_are: "",
                //     purchase_vat: "",
                //     type: "",
                //     salesman: "",
                // },
            },
            currencyOptions: [],
            amountsAreOptions: ["Tax Inclusive", "Tax Exclusive", "No Tax"],
            purchaseVatOptions: ["12.5% (VAT on expenses)", "20% (VAT on expenses)", "20% (VAT on income)", "EC acquisitions (205)"],
            loadingState: {
                currency: false,
                client: false,
            },
            clientId: null,
        };
    },
    computed:{
        isFormSubmit(){
            return this.loadingStates.formSubmit
        }
    },
    async mounted() {
        this.loadingState.client = true;
        await this.getCurrencyLists();
        await this.getClientDetails();
    },
    methods: {
        async getCurrencyLists() {
            this.loadingState.currency = true;
            this.currencyOptions = await getCurrencyList();
            this.loadingState.currency = false;
        },
        updateCurrency(value) {
            this.financials.financial_details.currency = value.code;
        },
        async getClientDetails() {
            this.loadingState.client = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                let response = await fetchClientInfo(this.clientId);
                let { tax_number, currency } = response;
                this.financials = { ...this.financials, financial_details: { tax_number, currency } };
            }
            this.loadingState.client = false;
        },
        handleSubmit(hasErrors) {
            if (hasErrors) {
                this.$toast.error(`Please fill all the required fields`);
            } else {
                let payload = {
                    data: {...this.financials.financial_details},
                    onBoard: false
                }
                this.$emit("submitForm", payload);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
